import Repository from '../services/repository.services'
const PREFIX = 'accountstatement/';

const state = {
    detailAccountList: [],
    recieverList: [],
    success: false
}

const getters = {
    detailAccount: (state) => state.detailAccountList,
    emailList: (state) => state.recieverList,
}
const actions = {
    getClientAccount({ commit }, uuid){
        return Repository.get(`${PREFIX}client/${uuid}`).then(            
            response => {
                commit('SET_ACCOUNT', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getRecievers( {commit }, uuid){
        return Repository.get(`${PREFIX}recievers/${uuid}`).then(            
            response => {
                commit('SET_RECIEVERS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    createReciever({ commit }, data){
        return Repository.post(data, `${PREFIX}reciever`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    deleteArray({ commit }, data){
        return Repository.delete(data, `${PREFIX}reciever`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    updateReciever({ commit }, data){
        return Repository.update(data, `${PREFIX}reciever`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    sendmail({ commit }, data){                   
        return Repository.post(data, `${PREFIX}sendmail`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    downloadF64({ commit }, data){                   
        return Repository.post(data, `${PREFIX}pdf64`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response);
            },
            error => {
                return Promise.reject(error);
            }
        );
    }
}

const mutations = {
    SET_ACCOUNT(state, res) {
        state.detailAccountList = res.data.data;
    },   
    SET_RECIEVERS(state, res){
        state.recieverList = res.data.data;
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const accountstatement = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}